import React from "react"
import { Offer } from "../../types/offer"

type Props = {
  offer: Offer
  style: "large" | "small"
}

const getLabel = (offer: Offer, style: "large" | "small") => {
  if (!offer.status) {
    return {
      label: style === "large" ? "Legacy Offer" : "Legacy",
      className: "bg-gray-100 text-gray-800",
    }
  }

  if (offer.status === "DE") {
    return {
      label: style === "large" ? "Deleted by Ostrich" : "Deleted",
      className: "bg-gray-100 text-gray-800",
    }
  }

  if (offer.status === "CM") {
    return {
      label: "Completed",
      className: "bg-green-100 text-green-800",
    }
  }

  if (offer.status === "UO") {
    return {
      label: "Under Offer",
      className: "bg-yellow-100 text-yellow-800",
    }
  }

  if (offer.status === "TO") {
    return {
      label: "Timed Out",
      className: "bg-gray-100 text-gray-800",
    }
  }

  if (offer.approved === true) {
    if (offer.pendingStatus) {
      return {
        label:
          style === "large"
            ? "Review Required - Homeowner Responded"
            : "Review Required",
        className: "bg-yellow-100 text-yellow-800",
      }
    }

    if (offer.pendingMessageReview) {
      return {
        label: "Review Required",
        className: "bg-yellow-100 text-yellow-800",
      }
    }

    switch (offer.status) {
      case "AW":
        return {
          label:
            style === "large"
              ? "Awaiting Homeowner Response"
              : "Awaiting Response",
          className: "bg-blue-100 text-blue-800",
        }

      case "DI":
        return {
          label: "In Discussion",
          className: "bg-green-100 text-green-800",
        }

      case "HP":
        return {
          label: "Rejected - Looking for higher price",
          className: "bg-red-100 text-red-800",
        }

      case "WT":
        return {
          label: "Rejected - Not the right time",
          className: "bg-red-100 text-red-800",
        }

      case "CL":
        return {
          label: style === "large" ? "Closed by Ostrich" : "Closed",
          className: "bg-gray-100 text-gray-800",
        }
    }
  }

  if (offer.approved === false) {
    return {
      label: style === "large" ? "Rejected by Ostrich" : "Rejected",
      className: "bg-red-100 text-red-800",
    }
  }

  return {
    label:
      style === "large" ? "Review Required - New Offer" : "Review Required",
    className: "bg-yellow-100 text-yellow-800",
  }
}

export const OfferStatusLabel: React.FC<Props> = ({ offer, style }) => {
  return (
    <>
      <span
        className={`inline-flex items-center rounded-full text-xs font-medium ${
          style === "large" ? "py-1 px-2.5" : "px-2.5 py-0.5"
        } ${getLabel(offer, style).className}`}
      >
        {getLabel(offer, style).label}
      </span>
    </>
  )
}
