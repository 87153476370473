import { ReactElement, useEffect, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import {
  ViewingFilters,
  decodeFilters,
  encodeFilters,
  fetchViewings,
} from "../../redux/slices/viewing-list"
import { Container } from "../global/container"
import { Header } from "../global/header"
import { ViewingTable } from "./viewing-table"
import { MinusIcon, PlusIcon } from "@heroicons/react/outline"
import { ViewingFiltersPanel } from "./viewing-filters"

export const ViewingList: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(fetchViewings())
  }, [dispatch])

  const { viewings } = useAppSelector((state) => state.viewingList)
  const [isPastVisible, setPastVisible] = useState(false)
  const [filters, setFilters] = useState<ViewingFilters>({
    date: undefined,
    status: [],
  })
  const [searchParams, setSearchParams] = useSearchParams()
  const filteredViewings = useMemo(() => {
    const currentDate = new Date()
    const pastViewings = []
    const upcomingViewings = []

    for (const viewing of viewings) {
      if (
        filters.date &&
        new Date(viewing.dateViewing).toISOString().substring(0, 10) !==
          filters.date
      ) {
        continue
      }

      if (filters.status && !filters.status.includes(viewing.status)) {
        continue
      }

      const viewingDate = new Date(viewing.dateViewing)
      if (viewingDate < currentDate) {
        pastViewings.push(viewing)
      } else {
        upcomingViewings.push(viewing)
      }
    }

    const past = filters.date
      ? pastViewings.filter(
          (viewing) =>
            new Date(viewing.dateViewing).toISOString().substring(0, 10) ===
            filters.date
        )
      : pastViewings

    const upcoming = filters.date
      ? upcomingViewings.filter(
          (viewing) =>
            new Date(viewing.dateViewing).toISOString().substring(0, 10) ===
            filters.date
        )
      : upcomingViewings

    return {
      past,
      upcoming,
    }
  }, [viewings, filters.status, filters.date])

  useEffect(() => {
    const filters = decodeFilters(searchParams)

    setFilters(filters)
  }, [dispatch, searchParams])

  const handleChangeFilters = (filters: ViewingFilters) => {
    setSearchParams(encodeFilters(filters))
  }

  const availableViewingDates = viewings.map((viewing) =>
    new Date(viewing.dateViewing).toISOString().substring(0, 10)
  )

  return (
    <>
      <Header
        title="Viewings"
        primaryButton={{
          label: "Create Viewing",
          onClick: () => navigate("/viewings/new/"),
        }}
      />
      <Container>
        <ViewingFiltersPanel
          filters={filters}
          onChange={handleChangeFilters}
          availableViewingDates={availableViewingDates}
        />
        <div className="flex flex-col gap-y-2 lg:gap-y-4">
          {filteredViewings.upcoming && (
            <div>
              <h3 className="px-4 py-4 text-lg font-medium text-gray-700 lg:px-6">
                Upcoming Bookings{" "}
                <span className="text-gray-500">
                  ({filteredViewings.upcoming.length})
                </span>
              </h3>
              <ViewingTable viewings={filteredViewings.upcoming} />
            </div>
          )}
          {filteredViewings.past && (
            <div>
              <button
                className="flex w-full cursor-pointer items-center justify-between rounded-md px-4 py-4 text-lg font-medium text-gray-700 hover:bg-gray-200 lg:px-6"
                onClick={() => setPastVisible(!isPastVisible)}
              >
                <p>
                  Past Bookings{" "}
                  <span className="text-gray-500">
                    ({filteredViewings.past.length})
                  </span>
                </p>
                {isPastVisible ? (
                  <MinusIcon className="w-5" />
                ) : (
                  <PlusIcon className="w-5" />
                )}
              </button>
              {isPastVisible && (
                <ViewingTable viewings={filteredViewings.past} />
              )}
            </div>
          )}
        </div>
      </Container>
    </>
  )
}
