import { MinusIcon, PlusIcon } from "@heroicons/react/outline"
import { ReactElement, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { fetchListingAppointments } from "../../../redux/slices/listing-appointments-list"
import { Container } from "../../global/container"
import { Header } from "../../global/header"
import { BookingsTabs } from "../bookings-tabs"
import { BookingsTable } from "./bookings-table"
import { useSearchParams } from "react-router-dom"
import { DateFilter } from "./date-filter"

export const BookingsList: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch()

  const [searchParams, setSearchParams] = useSearchParams()

  const date = searchParams.get("date")

  useEffect(() => {
    dispatch(fetchListingAppointments(date))
  }, [date])

  const handleChangeDate = (value: string | null) => {
    let params = new URLSearchParams()

    if (value) {
      params.append("date", value)
    }

    setSearchParams(params)
  }

  const { listingAppointments } = useAppSelector(
    (state) => state.listingAppointmentsList
  )

  const [isPastVisible, setPastVisible] = useState(false)
  const [isCancelledVisible, setCancelledVisible] = useState(false)
  const [isPostponedVisible, setPostponedVisible] = useState(false)

  return (
    <>
      <Header title="Bookings" />
      <BookingsTabs currentTab="Bookings" />
      <Container>
        <div className="flex justify-end">
          <DateFilter
            value={date}
            dates={listingAppointments.dates}
            onChange={handleChangeDate}
          />
        </div>
        <div className="flex flex-col gap-y-2 lg:gap-y-4">
          {listingAppointments.upcoming && (
            <div>
              <h3 className="px-4 py-4 text-lg font-medium text-gray-700 lg:px-6">
                Upcoming Bookings{" "}
                <span className="text-gray-500">
                  ({listingAppointments.upcoming.length})
                </span>
              </h3>
              <BookingsTable
                listingAppointments={listingAppointments.upcoming}
              />
            </div>
          )}
          {listingAppointments.postponed && (
            <div>
              <button
                className="flex w-full cursor-pointer items-center justify-between rounded-md px-4 py-4 text-lg font-medium text-gray-700 hover:bg-gray-200 lg:px-6"
                onClick={() => setPostponedVisible(!isPostponedVisible)}
              >
                <p>
                  Postponed Bookings{" "}
                  <span className="text-gray-500">
                    ({listingAppointments.postponed.length})
                  </span>
                </p>
                {isPostponedVisible ? (
                  <MinusIcon className="w-5" />
                ) : (
                  <PlusIcon className="w-5" />
                )}
              </button>
              {isPostponedVisible && (
                <BookingsTable
                  listingAppointments={listingAppointments.postponed}
                />
              )}
            </div>
          )}
          {listingAppointments.cancelled && (
            <div>
              <button
                className="flex w-full cursor-pointer items-center justify-between rounded-md px-4 py-4 text-lg font-medium text-gray-700 hover:bg-gray-200 lg:px-6"
                onClick={() => setCancelledVisible(!isCancelledVisible)}
              >
                <p>
                  Cancelled Bookings{" "}
                  <span className="text-gray-500">
                    ({listingAppointments.cancelled.length})
                  </span>
                </p>
                {isCancelledVisible ? (
                  <MinusIcon className="w-5" />
                ) : (
                  <PlusIcon className="w-5" />
                )}
              </button>
              {isCancelledVisible && (
                <BookingsTable
                  listingAppointments={listingAppointments.cancelled}
                />
              )}
            </div>
          )}
          {listingAppointments.past && (
            <div>
              <button
                className="flex w-full cursor-pointer items-center justify-between rounded-md px-4 py-4 text-lg font-medium text-gray-700 hover:bg-gray-200 lg:px-6"
                onClick={() => setPastVisible(!isPastVisible)}
              >
                <p>
                  Past Bookings{" "}
                  <span className="text-gray-500">
                    ({listingAppointments.past.length})
                  </span>
                </p>
                {isPastVisible ? (
                  <MinusIcon className="w-5" />
                ) : (
                  <PlusIcon className="w-5" />
                )}
              </button>
              {isPastVisible && (
                <BookingsTable listingAppointments={listingAppointments.past} />
              )}
            </div>
          )}
        </div>
      </Container>
    </>
  )
}
