import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Offer } from "../../types/offer"
import { getEndpoint } from "../../util/api"

export type OfferFilters = {
  status?: string[]
  includeUnverifiedUsers?: boolean
}

export const decodeFilters = (searchParams: URLSearchParams): OfferFilters => {
  const filters: OfferFilters = {}

  const status = searchParams
    .get("status")
    ?.split(",")
    .filter((s) => s.length)

  if (status?.length) {
    filters.status = status
  }

  const includeUnverifiedUsers = searchParams.get("includeUnverifiedUsers")

  if (includeUnverifiedUsers) {
    filters.includeUnverifiedUsers = true
  }

  return filters
}

export const encodeFilters = (filters: OfferFilters): URLSearchParams => {
  const params = new URLSearchParams()

  if (filters.status?.length) {
    params.set("status", filters.status.join(","))
  }

  if (filters.includeUnverifiedUsers) {
    params.set("includeUnverifiedUsers", "true")
  }

  return params
}

type State = {
  offers: any[]
}

const initialState: State = {
  offers: [],
}

export const fetchOffers = createAsyncThunk(
  "offerList/fetchOffers",
  async (filters: OfferFilters, thunkAPI) => {
    const response = await fetch(
      `${getEndpoint()}/offers/?${encodeFilters(filters).toString()}`,
      {
        cache: "no-cache",
        credentials: "include",
      }
    )

    const json = (await response.json()) as { offers?: Offer[] }

    return json.offers ?? []
  }
)

export const offerListSlice = createSlice({
  name: "offerList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOffers.fulfilled, (state, action) => {
      state.offers = action.payload
    })
  },
})

export const offerListReducer = offerListSlice.reducer
