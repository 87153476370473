import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { User } from "../../types/user"
import { getEndpoint } from "../../util/api"
import { UserErrors, validateUser } from "../../util/validation"
import { Viewing } from "../../types/viewings"
import { Offer } from "../../types/offer"

type State = {
  user?: User
  viewings?: Viewing[]
  offers?: Offer[]
  errors?: UserErrors
  status?: string
}

const initialState: State = {}

export const fetchUser = createAsyncThunk(
  "userDetails/fetchUser",
  async (id: string, thunkAPI) => {
    const response = await fetch(`${getEndpoint()}/users/${id}/`, {
      cache: "no-cache",
      credentials: "include",
    })

    const json = (await response.json()) as { user: User }

    return json.user
  }
)

export const fetchUserViewingsAndOffers = createAsyncThunk(
  "userDetails/fetchUserViewingsAndOffers",
  async (id: string, thunkAPI) => {
    // API call to fetch user details
    const userResponse = await fetch(`${getEndpoint()}/users/${id}/`, {
      cache: "no-cache",
      credentials: "include",
    })

    if (!userResponse.ok) {
      throw new Error("Failed to fetch user.")
    }

    const userJson = (await userResponse.json()) as { user: User }
    const user = userJson.user

    // API call to fetch viewings, using user.id as a query parameter
    const viewingsResponse = await fetch(
      `${getEndpoint()}/viewings/?userId=${user.id}`,
      {
        method: "GET",
        cache: "no-cache",
        credentials: "include",
      }
    )

    if (!viewingsResponse.ok) {
      throw new Error("Failed to fetch viewings.")
    }

    const viewings = (await viewingsResponse.json()).viewings

    // API call to fetch offers, using user.id as a query parameter
    const offersResponse = await fetch(
      `${getEndpoint()}/offers/?userId=${user.id}`,
      {
        method: "GET",
        cache: "no-cache",
        credentials: "include",
      }
    )

    if (!offersResponse.ok) {
      throw new Error("Failed to fetch offers.")
    }

    const offers = (await offersResponse.json()).offers

    return {
      user,
      viewings,
      offers,
    }
  }
)

export const saveUser = createAsyncThunk(
  "userDetails/saveUser",
  async (_: void, thunkAPI: any) => {
    const { user } = thunkAPI.getState().userDetails

    let errors = validateUser(user)

    if (Object.keys(errors).length) {
      return thunkAPI.rejectWithValue(errors)
    }

    const response = await fetch(`${getEndpoint()}/users/${user.id}/`, {
      method: "POST",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    })

    if (response.status !== 200) {
      return thunkAPI.rejectWithValue({})
    }

    const json = (await response.json()) as { user?: User }

    if (!json.user) {
      return thunkAPI.rejectWithValue({})
    }

    return json.user
  }
)

export const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    updateField: (
      state,
      action: PayloadAction<{ field: string; value?: any }>
    ) => {
      if (!state.user) return

      state.user[action.payload.field] = action.payload.value ?? ""
      state.status = undefined
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state, action) => {
        if (state.user && state.user.id !== action.meta.arg) {
          delete state.user
        }
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload
      })
      .addCase(fetchUserViewingsAndOffers.fulfilled, (state, action) => {
        state.user = action.payload.user
        state.viewings = action.payload.viewings
        state.offers = action.payload.offers
      })
      .addCase(saveUser.fulfilled, (state, action) => {
        state.user = action.payload
        state.errors = undefined
        state.status = "success"
      })
      .addCase(saveUser.rejected, (state, action) => {
        let errors = action.payload as UserErrors

        state.errors = errors
        state.status = "error"
      })
  },
})

export const { updateField } = userDetailsSlice.actions

export const userDetailsReducer = userDetailsSlice.reducer
