import dayjs from "dayjs"
import { ReactElement, useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { Container } from "../global/container"
import { Header } from "../global/header"
import {
  cancelViewing,
  fetchViewing,
  newViewing,
  saveViewing,
} from "../../redux/slices/viewing-details"
import { ViewingForm } from "./viewing-form"
import { ConfirmModal } from "../global/modals/confirm-modal"

export const ViewingDetails: React.FC = (): ReactElement | null => {
  let { id, tab } = useParams()
  let queryParams = useSearchParams()[0]

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [cancelModalOpen, setCancelModalOpen] = useState(false)

  const state = useAppSelector((state) => state.viewingDetails)

  useEffect(() => {
    if (id && id !== state.viewing?.id) {
      if (id === "new") {
        dispatch(newViewing(queryParams.get("offerId")))
      } else {
        dispatch(fetchViewing(id))
      }
    }
  }, [dispatch, id])

  if (!state.viewing) {
    return null
  }

  const handleSave = async () => {
    try {
      const viewing = (await dispatch(saveViewing()).unwrap()).viewing

      if (id === "new" && viewing) {
        navigate(`/viewings/${viewing.id}/`, { replace: true })
      }
    } catch (err) {}
  }

  const handleCancel = () => {
    if (!state.viewing?.id) return

    dispatch(cancelViewing(state.viewing.id))
  }

  let title = "New Viewing"

  if (state.property?.displayAddress) {
    title = `Viewing for ${state.property.displayAddress}`
  }

  const isCancelled = state.viewing?.status === "CA"

  let isPast = true

  if (state.viewing.dateViewing) {
    let dateViewing = dayjs(state.viewing.dateViewing)

    isPast = dateViewing.isBefore(dayjs())
  }

  return (
    <>
      <Header
        title={title}
        titleTag={isCancelled ? "Cancelled" : undefined}
        breadcrumbs={[
          { label: "Viewings", url: "/viewings/" },
          { label: "Edit Viewing" },
        ]}
        primaryButton={{ label: "Save", onClick: handleSave }}
        secondaryButton={
          isCancelled || isPast || state.newViewing
            ? undefined
            : {
                label: "Cancel Viewing",
                onClick: () => setCancelModalOpen(true),
              }
        }
        successMessage={
          state.status === "success" ? "Viewing saved" : undefined
        }
        errorMessage={
          state.status === "error"
            ? state.errorMessage ?? "Save error"
            : undefined
        }
        dateCreated={state.viewing?.dateCreated}
        dateUpdated={state.viewing?.dateUpdated}
      />
      <Container>
        <ViewingForm
          viewing={state.viewing}
          property={state.property}
          buyer={state.buyer}
          offers={state.offers}
          seller={state.seller}
          newViewing={state.newViewing}
          currentTab={tab ?? "details"}
          onSubmit={handleSave}
        />
      </Container>
      <ConfirmModal
        title="Cancel Viewing"
        message="Are you sure you want to cancel this viewing?"
        confirmLabel="Yes, Cancel Viewing"
        isOpen={cancelModalOpen}
        onConfirm={() => {
          handleCancel()
          setCancelModalOpen(false)
        }}
        onClose={() => {
          setCancelModalOpen(false)
        }}
      />
    </>
  )
}
