import {
  CurrencyPoundIcon,
  HomeIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline"

import { ChevronRightIcon } from "@heroicons/react/solid"
import { ReactElement } from "react"
import { Link } from "react-router-dom"
import { Offer } from "../../types/offer"
import { Pagination } from "../global/pagination"
import { OfferStatusLabel } from "./status-label"
import { ItemDates } from "../global/tables/item-dates"

type Props = {
  offers: Offer[]
}

export const OfferTable: React.FC<Props> = ({ offers }): ReactElement => {
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {offers.map((offer) => (
          <li key={offer.id}>
            <Link
              to={`/offers/${offer.id}/`}
              className="block hover:bg-gray-50"
            >
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="flex min-w-0 flex-1 items-center">
                  <div className="min-w-0 flex-1 md:grid md:grid-cols-3 md:gap-4">
                    <div>
                      <p className="flex items-center gap-2 truncate text-sm font-medium text-blue-600">
                        {offer.user.firstName} {offer.user.lastName}
                        {offer.status &&
                          offer.user.verified.stripe !== "VE" && (
                            <span
                              className={`inline-flex items-center rounded-full bg-orange-100 px-2.5 py-0.5 text-xs font-medium text-orange-800`}
                            >
                              Unverified
                            </span>
                          )}
                        <OfferStatusLabel offer={offer} style="large" />
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <InformationCircleIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <span className="mono truncate">{offer.id}</span>
                      </p>
                      {offer.viewingArranged && (
                        <p
                          className={`mr-2 mt-2 inline-flex items-center rounded-full bg-purple-100 px-2.5 py-0.5 text-xs font-medium text-purple-800`}
                        >
                          Viewing Arranged
                        </p>
                      )}
                      {offer.buyerContacted && (
                        <p
                          className={`mt-2 inline-flex items-center rounded-full bg-cyan-100 px-2.5 py-0.5 text-xs font-medium text-cyan-800`}
                        >
                          Buyer Contacted
                        </p>
                      )}
                    </div>
                    <div className="hidden md:block">
                      <div>
                        <ul className="mt-2 flex flex-col gap-1 text-sm text-gray-500">
                          <li className="flex">
                            <HomeIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            {offer.property.displayAddress}
                          </li>
                          <li className="flex">
                            <CurrencyPoundIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            {offer.offer
                              ? `£${offer.offer?.toLocaleString()}`
                              : "Buying agent"}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div>
                      <ItemDates item={offer} />
                    </div>
                  </div>
                </div>
                <div>
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
      <Pagination
        start={1}
        end={offers.length}
        total={offers.length}
        enablePrev={false}
        enableNext={false}
      />
    </div>
  )
}
