import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import slugify from "slugify"
import { v4 as uuidv4 } from "uuid"
import {
  Address,
  Neighbourhood,
  Property,
  PropertyImage,
  PropertySaleStatus,
  PropertyStatus,
  Renovation,
  TransportLink,
} from "../../types/property"
import { User } from "../../types/user"
import { formatAddress, sortAddresses } from "../../util/addresses"
import { getEndpoint } from "../../util/api"
import {
  PropertyErrors,
  PropertySectionErrors,
  validateProperty,
} from "../../util/validation"
import { ListingAppointment } from "../../types/listingAppointment"

type State = {
  property?: Property
  listingAppointment?: ListingAppointment
  errors?: PropertyErrors
  sectionErrors?: PropertySectionErrors
  errorMessage?: string
  addressOptions: Address[]
  transportOptions: TransportLink[]
  userOptions: User[]
  neighbourhoodOptions: Neighbourhood[]
  newProperty: boolean
  uploadingImages: boolean
  status?: string
}

const getInitialState = (): State => {
  return {
    addressOptions: [],
    transportOptions: [],
    userOptions: [],
    neighbourhoodOptions: [],
    newProperty: false,
    uploadingImages: false,
  }
}

export const fetchProperty = createAsyncThunk(
  "propertyDetails/fetchProperty",
  async (id: string, thunkAPI) => {
    const response = await fetch(`${getEndpoint()}/properties/${id}/`, {
      cache: "no-cache",
      credentials: "include",
    })

    const json = (await response.json()) as {
      property?: Property
      listingAppointment?: ListingAppointment
    }

    // Bit hacky, we should ideally treat studio differently
    if (json.property && !json.property.attributes.bedrooms) {
      json.property.attributes.bedrooms = 0
    }

    return json
  }
)

export const fetchNeighbourhoods = createAsyncThunk(
  "propertyDetails/fetchNeighbourhoods",
  async (_: void, thunkAPI: any) => {
    const response = await fetch(`${getEndpoint()}/misc/neighbourhoods/`, {
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })

    const json = (await response.json()) as { neighbourhoods: Neighbourhood[] }

    return json.neighbourhoods ?? []
  }
)

export const saveProperty = createAsyncThunk(
  "propertyDetails/saveProperty",
  async (_: void, thunkAPI: any) => {
    const { property, newProperty } = thunkAPI.getState().propertyDetails

    let validation = validateProperty(property)

    if (Object.keys(validation.errors).length) {
      return thunkAPI.rejectWithValue({ validation })
    }

    const response = await fetch(
      `${getEndpoint()}/properties/${newProperty ? "new" : property.id}/`,
      {
        method: "POST",
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...property,
          transportLinks:
            property.transportLinks?.map((transportLink: TransportLink) => {
              const { googleMapsUrl, id, ...rest } = transportLink

              return { ...rest, id: id || uuidv4() }
            }) ?? [],
        }),
      }
    )

    if (response.status !== 200) {
      return thunkAPI.rejectWithValue({})
    }

    const json = (await response.json()) as { property?: Property }

    // Bit hacky, we should ideally treat studio differently
    if (json.property && !json.property.attributes.bedrooms) {
      json.property.attributes.bedrooms = 0
    }

    if (!json.property) {
      return thunkAPI.rejectWithValue({})
    }

    return json.property
  }
)

export const lookupAddress = createAsyncThunk(
  "propertyDetails/lookupAddress",
  async (postcode: string, thunkAPI: any) => {
    if (!postcode) return []

    const response = await fetch(
      `${getEndpoint()}/misc/lookup-address/?postcode=${postcode}`,
      {
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )

    const json = (await response.json()) as { addresses: Address[] }

    return json.addresses ?? []
  }
)

export const lookupNearestTransportLinks = createAsyncThunk(
  "propertyDetails/lookupNearestTransportLinks",
  async (address: string, thunkAPI: any) => {
    if (!address) return []

    const response = await fetch(
      `${getEndpoint()}/misc/lookup-nearest-transport-links/?address=${encodeURIComponent(
        address
      )}`,
      {
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )

    const json = (await response.json()) as {
      nearestTransportLinks: TransportLink[]
    }

    return json.nearestTransportLinks ?? []
  }
)

export const uploadImages = createAsyncThunk(
  "propertyDetails/uploadImages",
  async (payload: { files: File[]; type: string }, thunkAPI: any) => {
    let images: PropertyImage[] = []

    for (let file of payload.files) {
      const data = new FormData()

      data.append(`file`, file)

      const response = await fetch(
        `${getEndpoint()}/misc/upload-image/?type=${payload.type}`,
        {
          method: "POST",
          cache: "no-cache",
          credentials: "include",
          body: data,
        }
      )

      const json = (await response.json()) as { images: PropertyImage[] }

      images = [...images, ...json.images]
    }

    return {
      images,
      type: payload.type,
    }
  }
)

export const propertyDetailsSlice = createSlice({
  name: "propertyDetails",
  initialState: getInitialState(),
  reducers: {
    addRenovation: (state) => {
      if (!state.property) return

      if (state.property.renovations) {
        state.property.renovations.push({
          id: uuidv4(),
        })
      } else {
        state.property.renovations = [{ id: uuidv4() }]
      }
    },
    copyRenovation: (state, action: PayloadAction<Renovation>) => {
      if (!state.property?.renovations) return

      state.property.renovations.push({ ...action.payload, id: uuidv4() })
    },
    deleteRenovation: (state, action: PayloadAction<string>) => {
      if (!state.property?.renovations) return

      state.property.renovations = state.property.renovations.filter(
        (r) => r.id !== action.payload
      )
    },
    updateAddress: (state, action: PayloadAction<string | undefined>) => {
      if (!state.property) return

      let address: Address | undefined

      if (action.payload) {
        address = state.addressOptions.find((a) => a.id === action.payload)
      }

      if (!address) return

      if (state.property.address && address.id === state.property.address.id) {
        return
      }

      state.property.address = address
      state.property.displayAddress = formatAddress(address)
      state.property.friendlyAddress = state.property.displayAddress
      state.property.slug = slugify(
        `${address.streetName}-${state.property.id.substring(0, 5)}`,
        {
          remove: /[*+~.()'"!:@]/g,
          lower: true,
        }
      )
    },
    updateField: (
      state,
      action: PayloadAction<{ field: string; value?: any }>
    ) => {
      if (!state.property) return

      state.property[action.payload.field] = action.payload.value ?? ""

      state.status = undefined
    },
    updateNeighbourhood: (state, action: PayloadAction<string | undefined>) => {
      if (!state.property) return

      let neighbourhood: Neighbourhood | undefined

      if (action.payload) {
        neighbourhood = state.neighbourhoodOptions.find(
          (n) => n.id === action.payload
        )
      }

      if (!neighbourhood) return

      state.property.neighbourhood = neighbourhood
    },
    updateRenovationField: (
      state,
      action: PayloadAction<{
        id: string
        field: string
        value?: string | number
      }>
    ) => {
      if (!state.property) return

      let renovations = state.property.renovations
      let renovation = renovations?.find((r) => r.id == action.payload.id)

      if (!renovation) return

      renovation[action.payload.field] = action.payload.value
    },
    newProperty: (state) => {
      let updatedState = getInitialState()

      updatedState.newProperty = true
      updatedState.property = {
        id: uuidv4(),
        status: PropertyStatus.Hidden,
        saleStatus: PropertySaleStatus.OffMarket,
        featured: false,
        intro: "",
        slug: "",
        lrIds: {
          sales: [],
        },
        attributes: {
          interiorSize: 0,
          outdoorSpace: "",
          bedrooms: 0,
          bathrooms: 0,
          tenure: "",
          buildingType: "",
          internalType: "",
          parking: "",
          epcUrl: "",
        },
        description: "",
        location: "",
        councilTax: "",
        localAuthority: "",
        renovations: [],
        transportLinks: [],
        floorplans: [],
        photos: [],
      }

      return updatedState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProperty.pending, (state) => {
        return getInitialState()
      })
      .addCase(fetchProperty.fulfilled, (state, action) => {
        state.property = action.payload.property
        state.listingAppointment = action.payload.listingAppointment
      })
      .addCase(fetchNeighbourhoods.fulfilled, (state, action) => {
        state.neighbourhoodOptions = action.payload
      })
      .addCase(saveProperty.fulfilled, (state, action) => {
        state.property = action.payload
        state.newProperty = false
        state.errors = undefined
        state.sectionErrors = undefined
        state.status = "success"
      })
      .addCase(saveProperty.rejected, (state, action) => {
        let payload = action.payload as {
          validation?: {
            errors: PropertyErrors
            sectionErrors: PropertySectionErrors
            errorMessage?: string
          }
        }

        if (payload.validation) {
          state.errors = payload.validation.errors
          state.sectionErrors = payload.validation.sectionErrors
          state.errorMessage = payload.validation.errorMessage
        }

        state.status = "error"
      })
      .addCase(lookupAddress.fulfilled, (state, action) => {
        state.addressOptions = sortAddresses(action.payload)
      })
      .addCase(lookupNearestTransportLinks.fulfilled, (state, action) => {
        if (state.property) {
          state.property.transportLinks = action.payload.map(
            (transportLink: TransportLink) => {
              return { ...transportLink, id: uuidv4() }
            }
          )
        }
      })
      .addCase(uploadImages.pending, (state) => {
        state.uploadingImages = true
      })
      .addCase(uploadImages.fulfilled, (state, action) => {
        state.uploadingImages = false

        if (!state.property) return
        if (action.payload.type == "photos") {
          state.property.photos = state.property.photos
            ? [...state.property.photos, ...action.payload.images]
            : action.payload.images
        } else {
          state.property.floorplans = state.property.floorplans
            ? [...state.property.floorplans, ...action.payload.images]
            : action.payload.images
        }
      })
      .addCase(uploadImages.rejected, (state) => {
        state.uploadingImages = false
      })
  },
})

export const {
  addRenovation,
  copyRenovation,
  deleteRenovation,
  updateAddress,
  updateField,
  updateNeighbourhood,
  updateRenovationField,
  newProperty,
} = propertyDetailsSlice.actions

export const propertyDetailsReducer = propertyDetailsSlice.reducer
