import { PencilAltIcon, PlusCircleIcon } from "@heroicons/react/outline"
import React from "react"
import { formatDateTime } from "../../../util/dates"

type Props = {
  item: {
    dateCreated: string
    dateUpdated?: string
  }
}

export const ItemDates: React.FC<Props> = ({ item }) => {
  return (
    <ul className="mt-2 flex flex-col gap-1 text-sm text-gray-500">
      {item.dateUpdated && item.dateUpdated !== item.dateCreated && (
        <li className="flex">
          <PencilAltIcon
            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
            aria-hidden="true"
          />
          <p>{formatDateTime(item.dateUpdated)}</p>
        </li>
      )}
      <li className="flex">
        <PlusCircleIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
          aria-hidden="true"
        />
        <p>{formatDateTime(item.dateCreated)}</p>
      </li>
    </ul>
  )
}
