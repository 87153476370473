import { ReactElement, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import {
  OfferFilters,
  decodeFilters,
  encodeFilters,
  fetchOffers,
} from "../../redux/slices/offer-list"
import { Container } from "../global/container"
import { Header } from "../global/header"
import { OfferTable } from "./offer-table"
import { OfferFiltersPanel } from "./offer-filters"

export const OfferList: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch()

  const [searchParams, setSearchParams] = useSearchParams()

  const [filters, setFilters] = useState<OfferFilters>({})

  useEffect(() => {
    let filters = decodeFilters(searchParams)

    dispatch(fetchOffers(filters))

    setFilters(filters)
  }, [dispatch, searchParams])

  const { offers } = useAppSelector((state) => state.offerList)

  const handleChangeFilters = (filters: OfferFilters) => {
    setSearchParams(encodeFilters(filters))
  }

  return (
    <>
      <Header title="Offers" />
      <Container>
        <OfferFiltersPanel filters={filters} onChange={handleChangeFilters} />
        {offers && <OfferTable offers={offers} />}
      </Container>
    </>
  )
}
