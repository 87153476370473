import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { PropertyOverview } from "../../types/property"
import { getEndpoint } from "../../util/api"

type State = {
  properties: PropertyOverview[]
}

const initialState: State = {
  properties: [],
}

export const fetchProperties = createAsyncThunk(
  "propertyList/fetchProperties",
  async (filters: string[], thunkAPI) => {
    const response = await fetch(
      `${getEndpoint()}/properties/${
        filters.length ? `?filters=${filters.join(",")}` : ""
      }`,
      {
        cache: "no-cache",
        credentials: "include",
      }
    )

    const json = (await response.json()) as { properties?: PropertyOverview[] }

    return json.properties ?? []
  }
)

export const propertyListSlice = createSlice({
  name: "propertyList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProperties.fulfilled, (state, action) => {
      state.properties = action.payload
    })
  },
})

export const propertyListReducer = propertyListSlice.reducer
