import { LocationMarkerIcon } from "@heroicons/react/outline"

import { ChevronRightIcon } from "@heroicons/react/solid"
import { ReactElement } from "react"
import { Link } from "react-router-dom"
import { ViewingListItem } from "../../types/viewings"
import { formatDateTime } from "../../util/dates"
import { Address } from "../../types/property"

type Props = {
  viewings: ViewingListItem[]
}

export const formatViewingAddress = (address: Address): string[] => {
  let lines: string[] = []

  if (address.streetName) {
    if (address.buildingUnit && address.buildingName) {
      lines.push(`${address.buildingUnit} ${address.buildingName}`)
    } else if (address.buildingUnit) {
      lines.push(address.buildingUnit)
    } else if (address.buildingName) {
      lines.push(address.buildingName)
    }

    if (address.streetNumber) {
      lines.push(`${address.streetNumber} ${address.streetName}`)
    } else {
      lines.push(address.streetName)
    }
  }

  lines.push(address.city)
  lines.push(address.postcode)

  return lines
}

export const ViewingTable: React.FC<Props> = ({ viewings }): ReactElement => {
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {viewings.map((viewing) => {
          const feedbackStatusColor =
            viewing.feedbackStatus === "NI"
              ? "gray"
              : viewing.feedbackStatus === "OM"
              ? "green"
              : viewing.feedbackStatus === "FV"
              ? "blue"
              : viewing.feedbackStatus === "QA"
              ? "orange"
              : "black"

          return (
            <li key={viewing.id}>
              <Link
                to={`/viewings/${viewing.id}/`}
                className="block hover:bg-gray-50"
              >
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="flex min-w-0 flex-1 items-center">
                    <div className="min-w-0 flex-1 md:grid md:grid-cols-3 md:gap-4">
                      <div className="flex flex-col gap-1 text-sm">
                        <p className="flex items-center gap-2 text-gray-500">
                          <span>{formatDateTime(viewing.dateViewing)}</span>
                          {viewing.status === "CA" && (
                            <span className="inline-flex items-center rounded-md bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">
                              Cancelled
                            </span>
                          )}
                          {viewing.feedbackStatus && (
                            <span
                              className={`inline-flex items-center rounded-md bg-${feedbackStatusColor}-100 px-2.5 py-0.5 text-xs font-medium text-${feedbackStatusColor}-800`}
                            >
                              {viewing.feedbackStatus === "NI"
                                ? "No Interest"
                                : viewing.feedbackStatus === "OM"
                                ? "Offer Made"
                                : viewing.feedbackStatus === "FV"
                                ? "Follow up viewing"
                                : viewing.feedbackStatus === "QA"
                                ? "Questions asked"
                                : ""}
                            </span>
                          )}
                        </p>
                        <p className="truncate font-medium text-blue-600">
                          {viewing.buyerName}
                        </p>
                      </div>
                      <div className="flex items-start gap-2 text-sm text-gray-800">
                        <LocationMarkerIcon className="w-5 text-gray-500" />
                        <div className="flex flex-col gap-1">
                          {formatViewingAddress(viewing.propertyAddress).map(
                            (line, i) => (
                              <p
                                className={i == 0 ? "font-medium" : ""}
                                key={`line-${i}`}
                              >
                                {line}
                              </p>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <ChevronRightIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
