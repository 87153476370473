import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ListingAppointment } from "../../types/listingAppointment"
import { getEndpoint } from "../../util/api"

type State = {
  listingAppointments: {
    upcoming?: ListingAppointment[]
    cancelled?: ListingAppointment[]
    postponed?: ListingAppointment[]
    past?: ListingAppointment[]
    dates?: string[]
  }
}

const initialState: State = {
  listingAppointments: {},
}

export const fetchListingAppointments = createAsyncThunk(
  "listingAppointmentsList/fetchListingAppointments",
  async (date: string | null, thunkAPI) => {
    const response = await fetch(
      `${getEndpoint()}/bookings/${date ? `?date=${date}` : ""}`,
      {
        cache: "no-cache",
        credentials: "include",
      }
    )

    const json = (await response.json()) as {
      upcoming?: ListingAppointment[]
      cancelled?: ListingAppointment[]
      postponed?: ListingAppointment[]
      past?: ListingAppointment[]
      dates?: string[]
    }

    return json
  }
)

export const listingAppointmentsListSlice = createSlice({
  name: "listingAppointmentsList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchListingAppointments.fulfilled, (state, action) => {
      state.listingAppointments = action.payload
    })
  },
})

export const listingAppointmentsListReducer =
  listingAppointmentsListSlice.reducer
