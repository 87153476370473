import { ReactElement, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { fetchProperties } from "../../redux/slices/property-list"
import { Container } from "../global/container"
import { Header } from "../global/header"
import { PropertyFiltersPanel } from "./property-filters"
import { PropertyTable } from "./property-table"

export const PropertyList: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const searchParams = useSearchParams()[0]

  const [filters, setFilters] = useState<string[]>([])

  useEffect(() => {
    let filters = searchParams.get("filters")?.split(",") ?? []

    setFilters(filters)

    dispatch(fetchProperties(filters))
  }, [dispatch, searchParams])

  const { properties } = useAppSelector((state) => state.propertyList)

  const handleChangeFilters = (filters: string[]) => {
    navigate(
      `/properties/${filters.length ? `?filters=${filters.join(",")}` : ""}`
    )
  }

  return (
    <>
      <Header
        title="Properties"
        primaryButton={{
          label: "Create Property",
          onClick: () => navigate("/properties/new/"),
        }}
      />
      <Container>
        <PropertyFiltersPanel
          filters={filters}
          onChange={handleChangeFilters}
        />
        {properties && <PropertyTable properties={properties} />}
      </Container>
    </>
  )
}
