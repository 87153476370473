import { ReactElement } from "react"
import { PropertyFilterOption } from "./property-filter-option"

type Props = {
  filters: string[]
  onChange: (filters: string[]) => void
}

const FILTER_TYPE = {
  HIDDEN: "hidden",
  LIVE: "live",
  PARTIAL: "partial",
}

export const PropertyFiltersPanel: React.FC<Props> = ({
  filters,
  onChange,
}): ReactElement => {
  const changeStatus = (status?: string) => {
    let updatedFilters = []

    if (status === FILTER_TYPE.HIDDEN) {
      updatedFilters.push(FILTER_TYPE.HIDDEN)
    } else if (status === FILTER_TYPE.LIVE) {
      updatedFilters.push(FILTER_TYPE.LIVE)
    } else if (status === FILTER_TYPE.PARTIAL) {
      updatedFilters.push(FILTER_TYPE.PARTIAL)
    }

    onChange(updatedFilters)
  }

  return (
    <div className="flex flex-col gap-0 overflow-hidden bg-white text-sm text-gray-800 shadow sm:mb-6 sm:flex-row sm:gap-12 sm:rounded-md sm:px-6 lg:mb-8">
      <div className="flex items-center border-b sm:border-0">
        <span className="w-1/3 border-r p-4 font-medium sm:w-auto sm:pl-0">
          Status
        </span>
        <PropertyFilterOption
          label="All"
          checked={
            !filters.includes(FILTER_TYPE.HIDDEN) &&
            !filters.includes(FILTER_TYPE.LIVE) &&
            !filters.includes(FILTER_TYPE.PARTIAL)
          }
          onSelect={() => changeStatus()}
        />
        <PropertyFilterOption
          label="Live"
          checked={filters.includes(FILTER_TYPE.LIVE)}
          onSelect={() => changeStatus(FILTER_TYPE.LIVE)}
        />
        <PropertyFilterOption
          label="Hidden"
          checked={filters.includes(FILTER_TYPE.HIDDEN)}
          onSelect={() => changeStatus(FILTER_TYPE.HIDDEN)}
        />
        <PropertyFilterOption
          label="Partial"
          checked={filters.includes(FILTER_TYPE.PARTIAL)}
          onSelect={() => changeStatus(FILTER_TYPE.PARTIAL)}
        />
      </div>
    </div>
  )
}
