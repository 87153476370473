import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ViewingListItem } from "../../types/viewings"
import { getEndpoint } from "../../util/api"

type State = {
  viewings: ViewingListItem[]
}

export type ViewingFilters = {
  status?: string[]
  date?: string
}

const initialState: State = {
  viewings: [],
}

export const decodeFilters = (
  searchParams: URLSearchParams
): ViewingFilters => {
  const filters: ViewingFilters = {}

  const status = searchParams
    .get("status")
    ?.split(",")
    .filter((s) => s.length)

  if (status?.length) {
    filters.status = status
  }

  const date = searchParams.get("date")

  if (date) {
    filters.date = date
  }

  return filters
}

export const encodeFilters = (filters: ViewingFilters): URLSearchParams => {
  const params = new URLSearchParams()

  if (filters.status?.length) {
    params.set("status", filters.status.join(","))
  }

  if (filters.date) {
    params.set("date", filters.date)
  }

  return params
}

export const fetchViewings = createAsyncThunk(
  "viewingList/fetchViewings",
  async (_: void, thunkAPI) => {
    const response = await fetch(`${getEndpoint()}/viewings/`, {
      cache: "no-cache",
      credentials: "include",
    })

    const json = (await response.json()) as {
      viewings?: ViewingListItem[]
    }

    return json.viewings ?? []
  }
)

export const viewingListSlice = createSlice({
  name: "viewingList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchViewings.fulfilled, (state, action) => {
      state.viewings = action.payload
    })
  },
})

export const viewingListReducer = viewingListSlice.reducer
